import React, { useEffect, useState } from "react"

function EsgCoursesFormBuilder() {
  const [schema, setSchema] = useState([])

  useEffect(() => {
    const schema = {
      full_name: {
        label: "Name",
        type: "text",
        required: true,
      },
      email: {
        label: "Email",
        type: "email",
        required: true,
      },
      company_name: {
        label: "Company Name",
        type: "text",
        required: true,
      },
      phone_number: {
        label: "Phone Number",
        type: "text",
        required: true,
      },
      number_of_employees: {
        label: "Number of Employees",
        type: "text",
        required: true,
      },
    }
    setSchema(schema)
  }, [])
  return schema
}

export default EsgCoursesFormBuilder
