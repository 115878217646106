import React from "react"
import axios from "axios"
import { ToastContainer, toast } from "react-toastify"
import { Form } from "skillstrainer-resource-library"
import EsgCoursesFormBuilder from "../formBuilder/esg-courses-form-builder"

export default function EsgCourses() {
  const handleSubmit = async register => {
    let payload
    console.log("register===", register)
    payload = {
      data: {
        entity: {
          type: "query",
          eventId: 1,
        },
        operation: "insert",

        data: {
          company_name: register.company_name,
          email: register.email,
          service_types: "Courses",
          full_name: register.full_name,
          phone_number: register.phone_number,
          number_of_employees: register.number_of_employees,
        },
      },
    }
    try {
      console.log(payload)
      const result = await axios.post(`${process.env.URL}/api/queries`, payload)

      if (result.status === 200) {
        return toast.success("Success", {
          position: toast.POSITION.TOP_CENTER,
        })
      } else {
        toast.error("Something gone wrong!", {
          position: toast.POSITION.TOP_CENTER,
        })
      }
    } catch (err) {
      console.log("I am error", err)
      return toast.error("Something gone wrong!", {
        position: toast.POSITION.TOP_CENTER,
      })
    }
  }

  return (
    <>
      <div className="mt-5 pt-3 px-5">
        <h1 className="heading mb-3">
          Contact for Bulk Capacity Building Licenses
        </h1>
        <ToastContainer />

        <Form
          formBuilder={EsgCoursesFormBuilder}
          className="ml-6 mt-2 grid grid-cols-2 gap-x-2"
          submitButton={{
            text: "Save",
            className: "btn learn-more-btn",
          }}
          onSubmit={handleSubmit}
        />
      </div>
    </>
  )
}
